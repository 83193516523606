import { GetterTree } from 'vuex';
import { RootState } from './index';

export interface Getters extends GetterTree<RootState, RootState> {
  updateAvailable: (state: RootState) => boolean;
}

const getters: Getters = {
  updateAvailable: (state: RootState) => state.updateAvailable
};

export default getters;

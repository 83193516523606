import Vue from 'vue';
import Component from 'vue-class-component';
import LinkedinImport from '@/components/linkedin-import';

import template from './linkedin-import.vue';

@Component({
  components: {
    LinkedinImport
  },
  mixins: [template],
  props: {
    componentProps: Object,
    visible: Boolean
  }
})
export default class LinkedinImportModal extends Vue {
  public get dialog(): boolean {
    return this.$props.visible;
  }

  public set dialog(status: boolean) {
    if (status) {
      return;
    }

    this.$emit('close');
  }
}

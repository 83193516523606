import * as firebase from 'firebase/app';
import { GetterTree, MutationTree, ActionContext, ActionTree } from 'vuex';

import { RootState } from '../index';
import {
  USER_LOGIN,
  USER_REGISTER,
  USER_LOGOUT,
  USER_GOOGLE_LOGIN,
  USER_FACEBOOK_LOGIN,
  USER_TWITTER_LOGIN,
  USER_GITHUB_LOGIN,
  USER_SOCIAL_LOGIN
} from '../action-types';
import router from '@/router';
import { User } from '@/interfaces';

const SET_USER = 'user/SET';
const CLEAR_USER = 'user/CLEAR';

const initialState: State = {
  user: null
};

const getters: Getters = {
  isLoggedIn(state: State) {
    return state.user !== null;
  },

  user(state: State) {
    return state.user;
  }
};

const actions: Actions = {
  async [USER_LOGIN](
    { commit }: ActionContext<State, {}>,
    { username, password }: ICredentials
  ) {
    const { user } = await firebase
      .auth()
      .signInWithEmailAndPassword(username, password);

    commit(SET_USER, user);

    router.push({ name: 'home' });
  },

  async [USER_SOCIAL_LOGIN](
    { commit }: ActionContext<State, {}>,
    provider: firebase.auth.AuthProvider
  ) {
    const { user } = await firebase.auth().signInWithPopup(provider);

    commit(SET_USER, user);

    router.push({ name: 'home' });
  },

  async [USER_GOOGLE_LOGIN]({ dispatch }: ActionContext<State, {}>) {
    const provider = new firebase.auth.GoogleAuthProvider();

    await dispatch(USER_SOCIAL_LOGIN, provider);
  },

  async [USER_FACEBOOK_LOGIN]({ dispatch }: ActionContext<State, {}>) {
    const provider = new firebase.auth.FacebookAuthProvider();

    await dispatch(USER_SOCIAL_LOGIN, provider);
  },

  async [USER_TWITTER_LOGIN]({ dispatch }: ActionContext<State, {}>) {
    const provider = new firebase.auth.TwitterAuthProvider();

    await dispatch(USER_SOCIAL_LOGIN, provider);
  },

  async [USER_GITHUB_LOGIN]({ dispatch }: ActionContext<State, {}>) {
    const provider = new firebase.auth.GithubAuthProvider();

    await dispatch(USER_SOCIAL_LOGIN, provider);
  },

  async [USER_REGISTER](
    { commit }: ActionContext<State, {}>,
    { username, password }: ICredentials
  ) {
    const { user } = await firebase
      .auth()
      .createUserWithEmailAndPassword(username, password);

    commit(SET_USER, user);

    router.push({ name: 'home' });
  },

  [USER_LOGOUT]({ commit }: ActionContext<State, {}>) {
    commit(CLEAR_USER);
  }
};

const mutations: Mutations = {
  [SET_USER](
    state: State,
    { uid, email, displayName, photoURL }: firebase.User
  ) {
    state.user = {
      uid,
      email,
      displayName,
      photoURL
    };
  },

  [CLEAR_USER](state: State) {
    state.user = null;
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface ICredentials {
  username: string;
  password: string;
}

export interface State {
  user: User | null;
}

export interface Getters extends GetterTree<State, RootState> {
  isLoggedIn: (state: State) => boolean;
  user: (state: State) => User | null;
}

export interface Actions extends ActionTree<State, RootState> {
  [USER_LOGIN]: (
    context: ActionContext<State, {}>,
    credentials: ICredentials
  ) => void;
  [USER_SOCIAL_LOGIN]: (
    context: ActionContext<State, {}>,
    provider: firebase.auth.AuthProvider
  ) => void;
  [USER_GOOGLE_LOGIN]: (context: ActionContext<State, {}>) => void;
  [USER_FACEBOOK_LOGIN]: (context: ActionContext<State, {}>) => void;
  [USER_TWITTER_LOGIN]: (context: ActionContext<State, {}>) => void;
  [USER_GITHUB_LOGIN]: (context: ActionContext<State, {}>) => void;
  [USER_REGISTER]: (
    context: ActionContext<State, {}>,
    credentials: ICredentials
  ) => void;
  [USER_LOGOUT]: (context: ActionContext<State, {}>) => void;
}

export interface Mutations extends MutationTree<State> {
  [SET_USER]: (state: State, user: firebase.User) => void;
  [CLEAR_USER]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

import Vue from 'vue';
import Component from 'vue-class-component';
import JobApplicationForm from '@/components/forms/job-application';

import template from './job-application-form.vue';

@Component({
  components: {
    JobApplicationForm
  },
  mixins: [template],
  props: {
    componentProps: Object,
    visible: Boolean
  }
})
export default class JobApplicationFormModal extends Vue {
  public get dialog(): boolean {
    return this.$props.visible;
  }

  public set dialog(status: boolean) {
    if (status) {
      return;
    }

    this.$emit('close');
  }
}

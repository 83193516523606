import { Profile } from '@/interfaces';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import gravatarUrl from 'gravatar-url';

import template from './avatar.vue';

@Component({
  mixins: [template]
})
export default class Avatar extends Vue {
  @Prop() public profile!: Profile;

  @Prop() public size: number = 110;

  public get gravatar() {
    return gravatarUrl(this.profile.email || 'gravatar@knohime.com', {
      size: 248,
      default: 'mp'
    });
  }
}

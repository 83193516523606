import Vue from 'vue';

import '@/plugins/sentry';

import '@/plugins/firebase';
import '@/plugins/nl2br';
import '@/plugins/validate';
import '@/plugins/vue-mq';
import vuetify from '@/plugins/vuetify';

import App from './App';
import router from './router';
import store from './store';

import { capitalize, dateFormat } from '@/filters';

import './registerServiceWorker';
import i18n from './i18n';

Vue.config.productionTip = false;

Vue.filter('capitalize', capitalize);
Vue.filter('dateFormat', dateFormat);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');

import { ID, Job, JobApplication } from '@/interfaces';
import uuid from 'uuid/v4';
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import { JOB_APPLICATION_SET_JOB } from '../action-types';
import {
  ADD_JOB,
  UPDATE_JOB,
  DELETE_JOB,
  ADD_JOB_APPLICATION,
  UPDATE_JOB_APPLICATION,
  DELETE_JOB_APPLICATION
} from '../mutation-types';

const initialState: State = {
  applications: [],
  jobs: []
};

const getters: Getters = {
  applicationById: (
    state: State,
    applicationId: ID
  ): JobApplication | undefined =>
    state.applications.find(({ id }) => id === applicationId),
  applications: (state: State): JobApplication[] => state.applications,
  applicationsGroupedByJob: (
    state: State
  ): {
    job: Job;
    applications: JobApplication[];
  }[] =>
    state.jobs.reduce<{ job: Job; applications: JobApplication[] }[]>(
      (acc, job) => [
        ...acc,
        {
          job,
          applications: state.applications.filter(
            ({ jobId }) => job.id === jobId
          )
        }
      ],
      []
    ),
  jobs: (state: State): Job[] => state.jobs
};

const actions: Actions = {
  [JOB_APPLICATION_SET_JOB]: (
    { commit }: ActionContext<State, RootState>,
    { applicationId, jobId }: { applicationId: ID; jobId: ID | null }
  ) => {
    // TODO: use application getter to retrieve application with id
    const application: { jobId: string | null } = { jobId: null };
    application.jobId = jobId;

    commit(UPDATE_JOB_APPLICATION, application);
  }
};

const mutations: Mutations = {
  [ADD_JOB](state: State, job: Job) {
    state.jobs = [
      ...state.jobs,
      {
        ...job,
        id: uuid()
      }
    ];
  },

  [UPDATE_JOB](state: State, job: Job) {
    state.jobs = state.jobs.map(item => (item.id === job.id ? job : item));
  },

  [DELETE_JOB](state: State, jobId: ID) {
    const index = state.jobs.findIndex(item => item.id === jobId);
    state.jobs = [
      ...state.jobs.slice(0, index),
      ...state.jobs.slice(index + 1)
    ];
  },

  [ADD_JOB_APPLICATION](state: State, application: JobApplication) {
    state.applications = [
      ...state.applications,
      {
        ...application,
        id: uuid()
      }
    ];
  },

  [UPDATE_JOB_APPLICATION](state: State, application: JobApplication) {
    state.applications = state.applications.map(
      item => (item.id === application.id ? application : item)
    );
  },

  [DELETE_JOB_APPLICATION](state: State, applicationId: ID) {
    const index = state.applications.findIndex(
      item => item.id === applicationId
    );
    state.applications = [
      ...state.applications.slice(0, index),
      ...state.applications.slice(index + 1)
    ];
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface State {
  applications: JobApplication[];
  jobs: Job[];
}

export interface Getters extends GetterTree<State, RootState> {
  applicationById: (
    state: State,
    applicationId: ID
  ) => JobApplication | undefined;
  applications: (state: State) => JobApplication[];
  applicationsGroupedByJob: (
    state: State
  ) => {
    job: Job;
    applications: JobApplication[];
  }[];
  jobs: (state: State) => Job[];
}

export interface Actions extends ActionTree<State, RootState> {
  [JOB_APPLICATION_SET_JOB]: (
    context: ActionContext<State, RootState>,
    payload: { applicationId: ID; jobId: ID | null }
  ) => void;
}

export interface Mutations extends MutationTree<State> {
  [ADD_JOB]: (state: State, job: Job) => void;
  [UPDATE_JOB]: (state: State, job: Job) => void;
  [DELETE_JOB]: (state: State, jobId: ID) => void;
  [ADD_JOB_APPLICATION]: (state: State, application: JobApplication) => void;
  [UPDATE_JOB_APPLICATION]: (state: State, application: JobApplication) => void;
  [DELETE_JOB_APPLICATION]: (state: State, applicationId: ID) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

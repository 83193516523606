import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { ID, Skill } from '@/interfaces';
import { DELETE_SKILL } from '@/store/mutation-types';

import template from './skills.vue';

@Component({
  mixins: [template]
})
export default class SkillsForm extends Vue {
  @Prop() public skills!: Skill[];

  @Mutation(DELETE_SKILL, { namespace: 'skill' })
  public deleteSkill!: (skillId: ID) => void;

  public handleClose() {
    this.$emit('close');
  }

  public async handleDelete(skill: Skill) {
    if (!skill.id) {
      return;
    }

    this.skills = this.skills.filter(s => s.id !== skill.id);
    this.deleteSkill(skill.id);
  }
}

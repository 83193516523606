import format from 'date-fns/format';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { ID, JobApplication } from '@/interfaces';
import {
  ADD_JOB_APPLICATION,
  DELETE_JOB_APPLICATION,
  UPDATE_JOB_APPLICATION
} from '@/store/mutation-types';

import template from './job-application.vue';

@Component({
  mixins: [template]
})
export default class JobApplicationForm extends Vue {
  @Prop() public application!: JobApplication;

  public form!: JobApplication;

  get appliedDate(): string {
    return format(this.form.date, 'YYYY-MM-DD');
  }

  set appliedDate(date: string) {
    this.form.date = new Date(date);
  }

  @Mutation(ADD_JOB_APPLICATION, { namespace: 'job' })
  public addJobApplication!: (jobApplication: JobApplication) => void;

  @Mutation(UPDATE_JOB_APPLICATION, { namespace: 'job' })
  public updateJobApplication!: (jobApplication: JobApplication) => void;

  @Mutation(DELETE_JOB_APPLICATION, { namespace: 'job' })
  public deleteJobApplication!: (jobApplicationId: ID) => void;

  public handleClose() {
    this.$emit('close');
  }

  public async handleSubmit() {
    await this.$validator.validateAll();
    if (this.$validator.errors.count() > 0) {
      return;
    }

    const mutation = this.form.id
      ? this.updateJobApplication
      : this.addJobApplication;
    mutation(this.form);

    this.handleClose();
  }

  public handleDelete() {
    if (!this.form.id) {
      return;
    }

    this.deleteJobApplication(this.form.id);
    this.handleClose();
  }

  public created() {
    this.form = Object.assign({}, this.application);
  }
}

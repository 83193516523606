import { MutationTree } from 'vuex';

import { RootState } from './index';
import { NOTIFY_UPDATE_AVAILABLE, MAKE_UPDATE_DONE } from './mutation-types';

export interface Mutations extends MutationTree<RootState> {
  [NOTIFY_UPDATE_AVAILABLE]: (state: RootState) => void;
  [MAKE_UPDATE_DONE]: (state: RootState) => void;
}

const mutations: Mutations = {
  [NOTIFY_UPDATE_AVAILABLE](state: RootState) {
    state.updateAvailable = true;
  },

  [MAKE_UPDATE_DONE](state: RootState) {
    state.updateAvailable = false;
  }
};

export default mutations;

import format from 'date-fns/format';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { ID, Education } from '@/interfaces';
import {
  ADD_EDUCATION,
  DELETE_EDUCATION,
  UPDATE_EDUCATION
} from '@/store/mutation-types';

import template from './education.vue';

@Component({
  mixins: [template]
})
export default class EducationForm extends Vue {
  @Prop() public education!: Education;

  public form!: Education;

  get fromDate(): string {
    return format(this.form.from, 'YYYY-MM');
  }

  set fromDate(date: string) {
    this.form.from = new Date(date);
  }

  get toDate(): string | undefined {
    return this.form.to && format(this.form.to, 'YYYY-MM');
  }

  set toDate(date: string | undefined) {
    this.form.to = date ? new Date(date) : undefined;
  }

  @Mutation(ADD_EDUCATION, { namespace: 'education' })
  public addEducation!: (education: Education) => void;

  @Mutation(UPDATE_EDUCATION, { namespace: 'education' })
  public updateEducation!: (education: Education) => void;

  @Mutation(DELETE_EDUCATION, { namespace: 'education' })
  public deleteEducation!: (educationId: ID) => void;

  public handleClose() {
    this.$emit('close');
  }

  public async handleSubmit() {
    await this.$validator.validateAll();
    if (this.$validator.errors.count() > 0) {
      return;
    }

    const mutation = this.form.id ? this.updateEducation : this.addEducation;
    mutation(this.form);

    this.handleClose();
  }

  public handleDelete() {
    if (!this.form.id) {
      return;
    }

    this.deleteEducation(this.form.id);
    this.handleClose();
  }

  public created() {
    this.form = Object.assign({}, this.education);
  }
}

import { ID, Education } from '@/interfaces';
import orderBy from 'lodash.orderby';
import uuid from 'uuid/v4';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import {
  ADD_EDUCATION,
  DELETE_EDUCATION,
  UPDATE_EDUCATION,
  CLEAR_EDUCATION
} from '../mutation-types';

const initialState: State = {
  educations: []
};

const getters: Getters = {
  educations: (state: State): Education[] =>
    orderBy(state.educations, ['from', 'to'], ['desc', 'desc'])
};

const actions: Actions = {};

const mutations: Mutations = {
  [ADD_EDUCATION](state: State, education: Education) {
    state.educations = [
      ...state.educations,
      {
        ...education,
        id: uuid()
      }
    ];
  },

  [UPDATE_EDUCATION](state: State, education: Education) {
    state.educations.map(item => (item.id === education.id ? education : item));
  },

  [DELETE_EDUCATION](state: State, educationId: ID) {
    const index = state.educations.findIndex(item => item.id === educationId);
    state.educations = [
      ...state.educations.slice(0, index),
      ...state.educations.slice(index + 1)
    ];
  },

  [CLEAR_EDUCATION](state: State) {
    state.educations = [];
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface State {
  educations: Education[];
}

export interface Getters extends GetterTree<State, RootState> {
  educations: (state: State) => Education[];
}

export interface Actions extends ActionTree<State, RootState> {}

export interface Mutations extends MutationTree<State> {
  [ADD_EDUCATION]: (state: State, education: Education) => void;
  [UPDATE_EDUCATION]: (state: State, education: Education) => void;
  [DELETE_EDUCATION]: (state: State, educationId: ID) => void;
  [CLEAR_EDUCATION]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

import { Cause, ID } from '@/interfaces';
import uuid from 'uuid/v4';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import {
  ADD_CAUSE,
  DELETE_CAUSE,
  UPDATE_CAUSE,
  CLEAR_CAUSE
} from '../mutation-types';

const initialState: State = {
  causes: []
};

const getters: Getters = {
  causes: (state: State): Cause[] => state.causes
};

const actions: Actions = {};

const mutations: Mutations = {
  [ADD_CAUSE](state: State, cause: Cause) {
    state.causes = [
      ...state.causes,
      {
        ...cause,
        id: uuid()
      }
    ];
  },

  [UPDATE_CAUSE](state: State, cause: Cause) {
    state.causes = state.causes.map(
      item => (item.id === cause.id ? cause : item)
    );
  },

  [DELETE_CAUSE](state: State, causeId: ID) {
    const index = state.causes.findIndex(item => item.id === causeId);
    state.causes = [
      ...state.causes.slice(0, index),
      ...state.causes.slice(index + 1)
    ];
  },

  [CLEAR_CAUSE](state: State) {
    state.causes = [];
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface State {
  causes: Cause[];
}

export interface Getters extends GetterTree<State, RootState> {
  causes: (state: State) => Cause[];
}

export interface Actions extends ActionTree<State, RootState> {}

export interface Mutations extends MutationTree<State> {
  [ADD_CAUSE]: (state: State, cause: Cause) => void;
  [UPDATE_CAUSE]: (state: State, cause: Cause) => void;
  [DELETE_CAUSE]: (state: State, causeId: ID) => void;
  [CLEAR_CAUSE]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

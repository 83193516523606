import { ID, Project } from '@/interfaces';
import orderBy from 'lodash.orderby';
import uuid from 'uuid/v4';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import {
  ADD_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  CLEAR_PROJECT
} from '../mutation-types';

const initialState: State = {
  projects: []
};

const getters: Getters = {
  projects: (state: State): Project[] =>
    orderBy(state.projects, ['from', 'to'], ['desc', 'desc'])
};

const actions: Actions = {};

const mutations: Mutations = {
  [ADD_PROJECT](state: State, project: Project) {
    state.projects = [
      ...state.projects,
      {
        ...project,
        id: uuid()
      }
    ];
  },

  [UPDATE_PROJECT](state: State, project: Project) {
    state.projects = state.projects.map(
      item => (item.id === project.id ? project : item)
    );
  },

  [DELETE_PROJECT](state: State, projectId: ID) {
    const index = state.projects.findIndex(item => item.id === projectId);
    state.projects = [
      ...state.projects.slice(0, index),
      ...state.projects.slice(index + 1)
    ];
  },

  [CLEAR_PROJECT](state: State) {
    state.projects = [];
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface State {
  projects: Project[];
}

export interface Getters extends GetterTree<State, RootState> {
  projects: (state: State) => Project[];
}

export interface Actions extends ActionTree<State, RootState> {}

export interface Mutations extends MutationTree<State> {
  [ADD_PROJECT]: (state: State, project: Project) => void;
  [UPDATE_PROJECT]: (state: State, project: Project) => void;
  [DELETE_PROJECT]: (state: State, projectId: ID) => void;
  [CLEAR_PROJECT]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

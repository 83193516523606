import Vue from 'vue';
import Component from 'vue-class-component';
import ProjectForm from '@/components/forms/project';

import template from './project-form.vue';

@Component({
  components: {
    ProjectForm
  },
  mixins: [template],
  props: {
    componentProps: Object,
    visible: Boolean
  }
})
export default class ProjectFormModal extends Vue {
  public get dialog(): boolean {
    return this.$props.visible;
  }

  public set dialog(status: boolean) {
    if (status) {
      return;
    }

    this.$emit('close');
  }
}

export const LINKEDIN_IMPORT = 'LINKEDIN_IMPORT';

export const JOB_APPLICATION_SET_JOB = 'job/application/SET_JOB';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_SOCIAL_LOGIN = 'USER_SOCIAL_LOGIN';
export const USER_GOOGLE_LOGIN = 'USER_GOOGLE_LOGIN';
export const USER_FACEBOOK_LOGIN = 'USER_FACEBOOK_LOGIN';
export const USER_TWITTER_LOGIN = 'USER_TWITTER_LOGIN';
export const USER_GITHUB_LOGIN = 'USER_GITHUB_LOGIN';
export const USER_REGISTER = 'USER_REGISTER';
export const USER_LOGOUT = 'USER_LOGOUT';

import format from 'date-fns/format';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { Profile } from '@/interfaces';
import Avatar from '@/components/avatar';
import { convertBlobToBase64 } from '@/lib/convertBlobToBase64';
import { UPDATE_PROFILE } from '@/store/mutation-types';

import template from './general.vue';

@Component({
  mixins: [template],
  components: {
    Avatar
  }
})
export default class ProfileForm extends Vue {
  @Prop() public profile!: Profile;

  public form!: Profile;

  public imageName: string = '';

  get birthDate(): string | undefined {
    return this.form.birhdate && format(this.form.birhdate, 'YYYY/MM/DD');
  }

  set birthDate(date: string | undefined) {
    this.form.birhdate = date ? new Date(date) : undefined;
  }

  @Mutation(UPDATE_PROFILE, { namespace: 'profile' })
  public updateProfile!: (experience: Profile) => void;

  public async handleChangePhoto(event: any) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    this.imageName = file.name;
    this.form.photo = await convertBlobToBase64(file);
  }

  public handleClickSelectPhoto() {
    (this.$refs.image as HTMLInputElement).click();
  }

  public handleClose() {
    this.$emit('close');
  }

  public async handleSubmit() {
    await this.$validator.validateAll();
    if (this.$validator.errors.count() > 0) {
      return;
    }

    this.updateProfile(this.form);

    this.handleClose();
  }

  public created() {
    this.form = Object.assign({}, this.profile);
  }
}

export function blobToBuffer(blob: Blob): Promise<Buffer> {
  const reader: FileReader = new FileReader();

  return new Promise((resolve, reject) => {
    function onLoadEnd(event: Event) {
      reader.removeEventListener('loadend', onLoadEnd, false);

      if (!(reader.result instanceof ArrayBuffer)) {
        return;
      }

      resolve(Buffer.from(reader.result));
    }

    reader.addEventListener('loadend', onLoadEnd, false);
    reader.readAsArrayBuffer(blob);
  });
}

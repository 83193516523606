import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapMutations } from 'vuex';

import CauseFormModal from '@/components/modals/cause-form';
import CertificationFormModal from '@/components/modals/certification-form';
import EducationFormModal from '@/components/modals/education-form';
import ExperienceFormModal from '@/components/modals/experience-form';
import GeneralFormModal from '@/components/modals/general-form';
import JobApplicationFormModal from '@/components/modals/job-application-form';
import LinkedinImportModal from '@/components/modals/linkedin-import';
import ProjectFormModal from '@/components/modals/project-form';
import SkillFormModal from '@/components/modals/skill-form';
import SkillsFormModal from '@/components/modals/skills-form';
import { CLOSE_MODAL } from '@/store/mutation-types';

import template from './modal.vue';

@Component({
  components: {
    CauseFormModal,
    CertificationFormModal,
    EducationFormModal,
    ExperienceFormModal,
    GeneralFormModal,
    JobApplicationFormModal,
    LinkedinImportModal,
    ProjectFormModal,
    SkillFormModal,
    SkillsFormModal
  },
  computed: {
    ...mapGetters({
      component: 'modal/component'
    })
  },
  methods: {
    ...mapMutations({
      closeModal: `modal/${CLOSE_MODAL}`
    })
  },
  mixins: [template]
})
export default class Modal extends Vue {}

import { ID, Skill } from '@/interfaces';
import uuid from 'uuid/v4';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import { ADD_SKILL, DELETE_SKILL, CLEAR_SKILL } from '../mutation-types';

const initialState: State = {
  skills: []
};

const getters: Getters = {
  skills: (state: State): Skill[] => state.skills
};

const actions: Actions = {};

const mutations: Mutations = {
  [ADD_SKILL](state: State, skill: string) {
    state.skills = [
      ...state.skills,
      {
        id: uuid(),
        name: skill
      }
    ];
  },

  [DELETE_SKILL](state: State, skillId: ID) {
    const index = state.skills.findIndex(item => item.id === skillId);
    state.skills = [
      ...state.skills.slice(0, index),
      ...state.skills.slice(index + 1)
    ];
  },

  [CLEAR_SKILL](state: State) {
    state.skills = [];
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface State {
  skills: Skill[];
}

export interface Getters extends GetterTree<State, RootState> {
  skills: (state: State) => Skill[];
}

export interface Actions extends ActionTree<State, RootState> {}

export interface Mutations extends MutationTree<State> {
  [ADD_SKILL]: (state: State, skill: string) => void;
  [DELETE_SKILL]: (state: State, skillId: ID) => void;
  [CLEAR_SKILL]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import { OPEN_MODAL, CLOSE_MODAL } from '../mutation-types';

const initialState: State = {
  name: null,
  props: null,
  visible: false
};

const getters: Getters = {
  component: ({ name, props, visible }: State) => ({ name, props, visible })
};

const actions: Actions = {};

const mutations: Mutations = {
  [OPEN_MODAL](state: State, { name, props = {} }) {
    state.name = name;
    state.props = props;
    state.visible = true;
  },

  [CLOSE_MODAL](state: State) {
    state.visible = false;
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface IModalParams {
  name: string;
  props: any;
}

export interface State {
  name: string | null;
  props: any;
  visible: boolean;
}

export interface Getters extends GetterTree<State, RootState> {
  component: (state: State) => Pick<State, 'name' | 'props' | 'visible'>;
}

export interface Actions extends ActionTree<State, RootState> {}

export interface Mutations extends MutationTree<State> {
  [OPEN_MODAL]: (state: State, component: IModalParams) => void;
  [CLOSE_MODAL]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

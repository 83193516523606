import Vue from 'vue';
import Router from 'vue-router';

import NotFound from './views/not-found';

const Home = () => import(/* webpackChunkName: "view-home" */ `@/views/home`);
const JobsApplications = () =>
  import(/* webpackChunkName: "view-jobs-applications" */ `@/views/jobs/applications`);
const Login = () =>
  import(/* webpackChunkName: "view-login" */ `@/views/login`);
const Register = () =>
  import(/* webpackChunkName: "view-register" */ `@/views/register`);

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      component: Home,
      name: 'home',
      path: '/'
    },
    { path: '/profile', redirect: '/' },
    {
      component: JobsApplications,
      name: 'jobsApplications',
      path: '/jobs/applications'
    },
    {
      component: Login,
      meta: { layout: 'no-sidebar-layout' },
      name: 'login',
      path: '/auth/login'
    },
    {
      component: Register,
      meta: { layout: 'no-sidebar-layout' },
      name: 'register',
      path: '/auth/register'
    },
    {
      component: NotFound,
      path: '*'
    }
  ]
});

import {
  CauseYouCareAbout,
  Certification,
  Education,
  LinkedinImport,
  Position,
  Project,
  Skill,
  TestScore
} from '@knohime/linkedin-import';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from './index';

import { LINKEDIN_IMPORT } from './action-types';
import {
  ADD_CAUSE,
  ADD_CERTIFICATION,
  ADD_EDUCATION,
  ADD_EXPERIENCE,
  ADD_PROJECT,
  ADD_SKILL,
  ADD_TEST_SCORE,
  UPDATE_PROFILE,
  CLEAR_CAUSE,
  CLEAR_CERTIFICATION,
  CLEAR_EDUCATION,
  CLEAR_EXPERIENCE,
  CLEAR_PROFILE,
  CLEAR_PROJECT,
  CLEAR_SKILL,
  CLEAR_TEST_SCORE
} from './mutation-types';

export interface Actions extends ActionTree<RootState, RootState> {
  [LINKEDIN_IMPORT]: (
    context: ActionContext<RootState, RootState>,
    file: Buffer
  ) => void;
}

const actions: Actions = {
  [LINKEDIN_IMPORT]: async (
    { commit }: ActionContext<RootState, RootState>,
    file: Buffer
  ) => {
    commit(`cause/${CLEAR_CAUSE}`);
    commit(`certification/${CLEAR_CERTIFICATION}`);
    commit(`education/${CLEAR_EDUCATION}`);
    commit(`experience/${CLEAR_EXPERIENCE}`);
    commit(`profile/${CLEAR_PROFILE}`);
    commit(`project/${CLEAR_PROJECT}`);
    commit(`skill/${CLEAR_SKILL}`);
    commit(`testScore/${CLEAR_TEST_SCORE}`);

    const {
      default: linkedinImport
    } = await import('@knohime/linkedin-import');
    const linkedinArchiveImported: LinkedinImport = await linkedinImport(file);

    (linkedinArchiveImported.causesYouCareAbout || []).forEach(
      (cause: CauseYouCareAbout) =>
        commit(
          `cause/${ADD_CAUSE}`,
          {
            name: cause.supportedCause
          },
          { root: true }
        )
    );

    (linkedinArchiveImported.certifications || []).forEach(
      (certification: Certification) =>
        commit(
          `certification/${ADD_CERTIFICATION}`,
          {
            authority: certification.authority || undefined,
            from: new Date(certification.startDate),
            license: certification.licenseNumber || undefined,
            name: certification.name,
            to:
              (certification.endDate && new Date(certification.endDate)) ||
              undefined,
            url: certification.url || undefined
          },
          { root: true }
        )
    );

    (linkedinArchiveImported.educations || []).forEach((education: Education) =>
      commit(
        `education/${ADD_EDUCATION}`,
        {
          activities: education.activities || undefined,
          degree: education.degreeName,
          description: education.notes || undefined,
          from: new Date(education.startDate),
          school: education.schoolName,
          to: (education.endDate && new Date(education.endDate)) || undefined
        },
        { root: true }
      )
    );

    (linkedinArchiveImported.positions || []).forEach((position: Position) =>
      commit(
        `experience/${ADD_EXPERIENCE}`,
        {
          company: position.companyName,
          description: position.description,
          from: new Date(position.startedOn),
          title: position.title,
          to:
            (position.finishedOn && new Date(position.finishedOn)) || undefined
        },
        { root: true }
      )
    );

    if (linkedinArchiveImported.profile) {
      commit(
        `profile/${UPDATE_PROFILE}`,
        {
          email:
            linkedinArchiveImported.emails &&
            linkedinArchiveImported.emails[0].emailAddress,
          firstname: linkedinArchiveImported.profile.firstName,
          lastname: linkedinArchiveImported.profile.lastName,
          location: linkedinArchiveImported.profile.country || undefined
        },
        { root: true }
      );
    }

    (linkedinArchiveImported.projects || []).forEach((project: Project) =>
      commit(
        `project/${ADD_PROJECT}`,
        {
          description: project.description,
          from: new Date(project.startDate),
          title: project.title,
          to: (project.endDate && new Date(project.endDate)) || undefined,
          url: project.url || undefined
        },
        { root: true }
      )
    );

    (linkedinArchiveImported.skills || []).forEach((skill: Skill) =>
      commit(`skill/${ADD_SKILL}`, skill.name, { root: true })
    );

    (linkedinArchiveImported.testScores || []).forEach((testScore: TestScore) =>
      commit(
        `testScore/${ADD_TEST_SCORE}`,
        {
          date: new Date(testScore.testDate),
          description: testScore.description,
          name: testScore.name,
          score: testScore.score
        },
        { root: true }
      )
    );
  }
};

export default actions;

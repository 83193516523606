import { Certification, ID } from '@/interfaces';
import orderBy from 'lodash.orderby';
import uuid from 'uuid/v4';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import {
  ADD_CERTIFICATION,
  DELETE_CERTIFICATION,
  UPDATE_CERTIFICATION,
  CLEAR_CERTIFICATION
} from '../mutation-types';

const initialState: State = {
  certifications: []
};

const getters: Getters = {
  certifications: (state: State): Certification[] =>
    orderBy(state.certifications, ['from', 'to'], ['desc', 'desc'])
};

const actions: Actions = {};

const mutations: Mutations = {
  [ADD_CERTIFICATION](state: State, certification: Certification) {
    state.certifications = [
      ...state.certifications,
      {
        ...certification,
        id: uuid()
      }
    ];
  },

  [UPDATE_CERTIFICATION](state: State, certification: Certification) {
    state.certifications = state.certifications.map(
      item => (item.id === certification.id ? certification : item)
    );
  },

  [DELETE_CERTIFICATION](state: State, certificationId: ID) {
    const index = state.certifications.findIndex(
      item => item.id === certificationId
    );
    state.certifications = [
      ...state.certifications.slice(0, index),
      ...state.certifications.slice(index + 1)
    ];
  },

  [CLEAR_CERTIFICATION](state: State) {
    state.certifications = [];
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface State {
  certifications: Certification[];
}

export interface Getters extends GetterTree<State, RootState> {
  certifications: (state: State) => Certification[];
}

export interface Actions extends ActionTree<State, RootState> {}

export interface Mutations extends MutationTree<State> {
  [ADD_CERTIFICATION]: (state: State, certification: Certification) => void;
  [UPDATE_CERTIFICATION]: (state: State, certification: Certification) => void;
  [DELETE_CERTIFICATION]: (state: State, certificationId: ID) => void;
  [CLEAR_CERTIFICATION]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

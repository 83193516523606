import { ID, TestScore } from '@/interfaces';
import orderBy from 'lodash.orderby';
import uuid from 'uuid/v4';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import {
  ADD_TEST_SCORE,
  DELETE_TEST_SCORE,
  UPDATE_TEST_SCORE,
  CLEAR_TEST_SCORE
} from '../mutation-types';

const initialState: State = {
  testScores: []
};

const getters: Getters = {
  testScores: (state: State): TestScore[] =>
    orderBy(state.testScores, ['from', 'to'], ['desc', 'desc'])
};

const actions: Actions = {};

const mutations: Mutations = {
  [ADD_TEST_SCORE](state: State, testScore: TestScore) {
    state.testScores = [
      ...state.testScores,
      {
        ...testScore,
        id: uuid()
      }
    ];
  },

  [UPDATE_TEST_SCORE](state: State, testScore: TestScore) {
    state.testScores.map(item => (item.id === testScore.id ? testScore : item));
  },

  [DELETE_TEST_SCORE](state: State, testScoreId: ID) {
    const index = state.testScores.findIndex(item => item.id === testScoreId);
    state.testScores = [
      ...state.testScores.slice(0, index),
      ...state.testScores.slice(index + 1)
    ];
  },

  [CLEAR_TEST_SCORE](state: State) {
    state.testScores = [];
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface State {
  testScores: TestScore[];
}

export interface Getters extends GetterTree<State, RootState> {
  testScores: (state: State) => TestScore[];
}

export interface Actions extends ActionTree<State, RootState> {}

export interface Mutations extends MutationTree<State> {
  [ADD_TEST_SCORE]: (state: State, testScore: TestScore) => void;
  [UPDATE_TEST_SCORE]: (state: State, testScore: TestScore) => void;
  [DELETE_TEST_SCORE]: (state: State, testScoreId: ID) => void;
  [CLEAR_TEST_SCORE]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { ID, Cause } from '@/interfaces';
import { ADD_CAUSE, DELETE_CAUSE, UPDATE_CAUSE } from '@/store/mutation-types';

import template from './cause.vue';

@Component({
  mixins: [template]
})
export default class CauseForm extends Vue {
  @Prop() public cause!: Cause;

  public form!: Cause;

  @Mutation(ADD_CAUSE, { namespace: 'cause' })
  public addCause!: (cause: Cause) => void;

  @Mutation(UPDATE_CAUSE, { namespace: 'cause' })
  public updateCause!: (cause: Cause) => void;

  @Mutation(DELETE_CAUSE, { namespace: 'cause' })
  public deleteCause!: (causeId: ID) => void;

  public handleClose() {
    this.$emit('close');
  }

  public async handleSubmit() {
    await this.$validator.validateAll();
    if (this.$validator.errors.count() > 0) {
      return;
    }

    const mutation = this.form.id ? this.updateCause : this.addCause;
    mutation(this.form);

    this.handleClose();
  }

  public handleDelete() {
    if (!this.form.id) {
      return;
    }

    this.deleteCause(this.form.id);
    this.handleClose();
  }

  public created() {
    this.form = Object.assign({}, this.cause);
  }
}

import { Profile } from '@/interfaces';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import { UPDATE_PROFILE, CLEAR_PROFILE } from '../mutation-types';

const initialState: State = {
  profile: {
    firstname: 'Firstname',
    headline: '',
    lastname: 'Lastname'
  }
};

const getters: Getters = {
  profile: (state: State): Profile => state.profile
};

const actions: Actions = {};

const mutations: Mutations = {
  [UPDATE_PROFILE](state: State, profile: Profile) {
    state.profile = profile;
  },

  [CLEAR_PROFILE](state: State) {
    state.profile = initialState.profile;
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface State {
  profile: Profile;
}

export interface Getters extends GetterTree<State, RootState> {
  profile: (state: State) => Profile;
}

export interface Actions extends ActionTree<State, RootState> {}

export interface Mutations extends MutationTree<State> {
  [UPDATE_PROFILE]: (state: State, profile: Profile) => void;
  [CLEAR_PROFILE]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

export const OPEN_MODAL = 'modal/OPEN';
export const CLOSE_MODAL = 'modal/CLOSE';

export const ADD_EDUCATION = 'education/ADD';
export const UPDATE_EDUCATION = 'education/UPDATE';
export const DELETE_EDUCATION = 'education/DELETE';
export const CLEAR_EDUCATION = 'education/CLEAR';

export const ADD_EXPERIENCE = 'experience/ADD';
export const UPDATE_EXPERIENCE = 'experience/UPDATE';
export const DELETE_EXPERIENCE = 'experience/DELETE';
export const CLEAR_EXPERIENCE = 'experience/CLEAR';

export const ADD_SKILL = 'skill/ADD';
export const DELETE_SKILL = 'skill/DELETE';
export const CLEAR_SKILL = 'skill/CLEAR';

export const UPDATE_PROFILE = 'profile/UPDATE';
export const CLEAR_PROFILE = 'profile/CLEAR';

export const ADD_PROJECT = 'project/ADD';
export const UPDATE_PROJECT = 'project/UPDATE';
export const DELETE_PROJECT = 'project/DELETE';
export const CLEAR_PROJECT = 'project/CLEAR';

export const ADD_CERTIFICATION = 'certification/ADD';
export const UPDATE_CERTIFICATION = 'certification/UPDATE';
export const DELETE_CERTIFICATION = 'certification/DELETE';
export const CLEAR_CERTIFICATION = 'certification/CLEAR';

export const ADD_TEST_SCORE = 'test-score/ADD';
export const UPDATE_TEST_SCORE = 'test-score/UPDATE';
export const DELETE_TEST_SCORE = 'test-score/DELETE';
export const CLEAR_TEST_SCORE = 'test-score/CLEAR';

export const ADD_CAUSE = 'cause/ADD';
export const UPDATE_CAUSE = 'cause/UPDATE';
export const DELETE_CAUSE = 'cause/DELETE';
export const CLEAR_CAUSE = 'cause/CLEAR';

export const ADD_JOB = 'job/ADD';
export const UPDATE_JOB = 'job/UPDATE';
export const DELETE_JOB = 'job/DELETE';

export const ADD_JOB_APPLICATION = 'job/application/ADD';
export const UPDATE_JOB_APPLICATION = 'job/application/UPDATE';
export const DELETE_JOB_APPLICATION = 'job/application/DELETE';

export const NOTIFY_UPDATE_AVAILABLE = 'NOTIFY_UPDATE_AVAILABLE';
export const MAKE_UPDATE_DONE = 'MAKE_UPDATE_DONE';

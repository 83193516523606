import { ID, Experience } from '@/interfaces';
import orderBy from 'lodash.orderby';
import uuid from 'uuid/v4';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '../index';
import {
  ADD_EXPERIENCE,
  DELETE_EXPERIENCE,
  UPDATE_EXPERIENCE,
  CLEAR_EXPERIENCE
} from '../mutation-types';

const initialState: State = {
  experiences: []
};

const getters: Getters = {
  experiences: (state: State): Experience[] =>
    orderBy(state.experiences, ['from', 'to'], ['desc', 'desc'])
};

const actions: Actions = {};

const mutations: Mutations = {
  [ADD_EXPERIENCE](state: State, experience: Experience) {
    state.experiences = [
      ...state.experiences,
      {
        ...experience,
        id: uuid()
      }
    ];
  },

  [UPDATE_EXPERIENCE](state: State, experience: Experience) {
    state.experiences = state.experiences.map(
      item => (item.id === experience.id ? experience : item)
    );
  },

  [DELETE_EXPERIENCE](state: State, experienceId: ID) {
    const index = state.experiences.findIndex(item => item.id === experienceId);
    state.experiences = [
      ...state.experiences.slice(0, index),
      ...state.experiences.slice(index + 1)
    ];
  },

  [CLEAR_EXPERIENCE](state: State) {
    state.experiences = [];
  }
};

const namespaced = true;

const module: Module = {
  actions,
  getters,
  mutations,
  namespaced,
  state: initialState
};

export default module;

export interface State {
  experiences: Experience[];
}

export interface Getters extends GetterTree<State, RootState> {
  experiences: (state: State) => Experience[];
}

export interface Actions extends ActionTree<State, RootState> {}

export interface Mutations extends MutationTree<State> {
  [ADD_EXPERIENCE]: (state: State, experience: Experience) => void;
  [UPDATE_EXPERIENCE]: (state: State, experience: Experience) => void;
  [DELETE_EXPERIENCE]: (state: State, experienceId: ID) => void;
  [CLEAR_EXPERIENCE]: (state: State) => void;
}

export interface Module {
  namespaced: boolean;
  state: State;
  getters: Getters;
  actions: Actions;
  mutations: Mutations;
}

import Vue from 'vue';
import { Action } from 'vuex-class';
import Component from 'vue-class-component';
import { blobToBuffer } from '@/lib/convertBlobToBuffer';
import { LINKEDIN_IMPORT } from '@/store/action-types';

import template from './linkedin-import.vue';

@Component({
  mixins: [template]
})
export default class LinkedinImport extends Vue {
  @Action(LINKEDIN_IMPORT) protected linkedinImport!: (file: Buffer) => void;

  public handleClose() {
    this.$emit('close');
  }

  public handleClickSelectArchive() {
    (this.$refs.upload as HTMLInputElement).click();
  }

  public async handleLinkedinUpload(event: any) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const buffer: Buffer = await blobToBuffer(file);
    this.linkedinImport(buffer);
    this.handleClose();
  }
}

import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import Modal from '@/components/modal';
import DefaultLayout from '@/layouts/default';
import NoSidebarLayout from '@/layouts/no-sidebar';

import template from './app.vue';

@Component({
  components: {
    DefaultLayout,
    NoSidebarLayout,
    Modal
  },
  computed: {
    ...mapGetters({
      updateAvailable: 'updateAvailable'
    })
  },
  mixins: [template]
})
export default class App extends Vue {
  public get layout() {
    return this.$route.meta.layout || 'default-layout';
  }

  public reloadApp() {
    window.location.reload();
  }
}

import Vue from 'vue';
import Component from 'vue-class-component';
import { Mutation } from 'vuex-class';

import { ADD_SKILL } from '@/store/mutation-types';

import template from './skill.vue';

@Component({
  mixins: [template]
})
export default class SkillAddForm extends Vue {
  public skill: string = '';

  @Mutation(ADD_SKILL, { namespace: 'skill' })
  public addSkill!: (skill: string) => void;

  public handleClose() {
    this.$emit('close');
  }

  public async handleSubmit() {
    await this.$validator.validateAll();
    if (this.$validator.errors.count() > 0) {
      return;
    }

    this.addSkill(this.skill);
    this.handleClose();
  }
}

import format from 'date-fns/format';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { ID, Project } from '@/interfaces';
import {
  ADD_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT
} from '@/store/mutation-types';

import template from './project.vue';

@Component({
  mixins: [template]
})
export default class ProjectForm extends Vue {
  @Prop() public project!: Project;

  public form!: Project;

  get fromDate(): string {
    return format(this.form.from, 'YYYY-MM');
  }

  set fromDate(date: string) {
    this.form.from = new Date(date);
  }

  get toDate(): string | undefined {
    return this.form.to && format(this.form.to, 'YYYY-MM');
  }

  set toDate(date: string | undefined) {
    this.form.to = date ? new Date(date) : undefined;
  }

  @Mutation(ADD_PROJECT, { namespace: 'project' })
  public addProject!: (project: Project) => void;

  @Mutation(UPDATE_PROJECT, { namespace: 'project' })
  public updateProject!: (project: Project) => void;

  @Mutation(DELETE_PROJECT, { namespace: 'project' })
  public deleteProject!: (projectId: ID) => void;

  public handleClose() {
    this.$emit('close');
  }

  public async handleSubmit() {
    await this.$validator.validateAll();
    if (this.$validator.errors.count() > 0) {
      return;
    }

    const mutation = this.form.id ? this.updateProject : this.addProject;
    mutation(this.form);

    this.handleClose();
  }

  public handleDelete() {
    if (!this.form.id) {
      return;
    }

    this.deleteProject(this.form.id);
    this.handleClose();
  }

  public created() {
    this.form = Object.assign({}, this.project);
  }
}

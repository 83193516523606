import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Action } from 'vuex-class';

import { USER_LOGOUT } from '@/store/action-types';

import template from './default.vue';

@Component({
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  mixins: [template]
})
export default class DefaultLayout extends Vue {
  public navItems = [
    { to: '/', icon: 'mdi-account-box', label: 'Profile' },
    { to: '/jobs/applications', icon: 'mdi-briefcase', label: 'Applications' }
  ];

  @Action(USER_LOGOUT, { namespace: 'user' })
  public logout!: () => void;
}

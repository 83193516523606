export function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result !== 'string') {
        return;
      }

      resolve(reader.result);
    };

    reader.readAsDataURL(blob);
  });
}

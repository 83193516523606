import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import modalModule, { State as ModalState } from './modules/modal';
import causeModule, { State as CauseState } from './modules/cause';
import certificationModule, {
  State as CertificationState
} from './modules/certification';
import educationModule, { State as EducationState } from './modules/education';
import experienceModule, {
  State as ExperienceState
} from './modules/experience';
import jobModule, { State as JobState } from './modules/job';
import profileModule, { State as ProfileState } from './modules/profile';
import projectModule, { State as ProjectState } from './modules/project';
import skillModule, { State as SkillState } from './modules/skill';
import testScoreModule, { State as TestScoreState } from './modules/test-score';
import userModule, { State as UserState } from './modules/user';

Vue.use(Vuex);

export interface RootState {
  updateAvailable: boolean;

  modal?: ModalState;
  cause?: CauseState;
  certification?: CertificationState;
  education?: EducationState;
  experience?: ExperienceState;
  job?: JobState;
  skill?: SkillState;
  profile?: ProfileState;
  project?: ProjectState;
  testScore?: TestScoreState;
  user?: UserState;
}

const initialState: RootState = {
  updateAvailable: false
};

const store = new Vuex.Store<RootState>({
  actions,
  getters,
  mutations,
  state: initialState,
  modules: {
    modal: modalModule,
    cause: causeModule,
    certification: certificationModule,
    education: educationModule,
    experience: experienceModule,
    job: jobModule,
    profile: profileModule,
    project: projectModule,
    skill: skillModule,
    testScore: testScoreModule,
    user: userModule
  },
  plugins: [
    createPersistedState({
      paths: [
        'cause',
        'certification',
        'education',
        'experience',
        'job',
        'profile',
        'project',
        'skill',
        'testScore',
        'user'
      ]
    })
  ],
  strict: process.env.NODE_ENV !== 'production'
});

export default store;
